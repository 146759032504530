import React from 'react'
import { CalloutCard, Card, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import { Route } from 'react-router-dom'
import { ErrorState } from './'
import { BeerSVG, SearchSVG } from '../assets'


export const BARCODE_STATUS_QUERY = gql`
{  
  barcodes {
    unused
  }
}
`

export const StatusCard: React.SFC = () => {
  return (
    <Query query={BARCODE_STATUS_QUERY}>
    {({ loading, error, data: { products, barcodes } }) => {
      if (loading) return <LoadingCard />
      return <RegularCard variantsThatNeedBarcodes={/* products.stats.needed */0} unusedBarcodes={barcodes.unused}/>
    }}
    </Query>
  )
}

const LoadingCard: React.SFC = () => (
  <Card.Section>
    <SkeletonDisplayText size="small" />
    <SkeletonBodyText lines={4} />
  </Card.Section>
)

const EmptyStateCard: React.SFC = () => (
  null // hooray! All of your product variants have barcodes!
)

type RegularCardProps = {
  variantsThatNeedBarcodes: number;
  unusedBarcodes: number;
}

const RegularCard: React.SFC<RegularCardProps> = ({ variantsThatNeedBarcodes, unusedBarcodes }) => (
  <Route render={({ history }) => (
    <Card.Section
      title={`You ${unusedBarcodes < 10 && unusedBarcodes >= 1 ? 'only' : ''} have ${unusedBarcodes ? unusedBarcodes : 0} unused barcodes remaining`}

      // illustration={variantsThatNeedBarcodes <= 0 ? BeerSVG : SearchSVG}
      // primaryAction={{content: 'Buy barcodes', primary: true, onAction: () => history.push("/a/store") }}
    >
      <p>{variantsThatNeedBarcodes <= 0 ? 'Hooray!' : ''} You have <i>{variantsThatNeedBarcodes}</i> product variants without barcodes (upc, etc). Buy more barcodes to ensure your products can be listed on <strong>Amazon, Houzz, and more.</strong></p>
    </Card.Section>
  )} />
)
