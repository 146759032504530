import React, { ReactNode } from 'react'
import * as PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import { Redirect } from '@shopify/app-bridge/actions';
import { LoadingPage } from '../pages'
import { ErrorState } from './'


type Props = {
  token?: string | false | null;
  shopName?: string | false | null;
  children?: ReactNode
}

const QUERY = gql`
query Token($token: String) {
  token(token: $token) {
    ok
  }
}
`

const AuthReloader: React.SFC<Props> = ({ token, shopName, children }, { polaris }) => {
  return (
    <Query query={QUERY} variables={{token}} pollInterval={900000}>
      {({loading, error, data}) => {
        if (loading) return <LoadingPage />;
        if (error) return <ErrorState error={{component: "AuthReloader", ...error}} />
        if (data.token.ok) {
          if (process.env.NODE_ENV === 'development') console.log(token);
          // @ts-ignore: Unreachable code error
          if (typeof window.tap !== 'undefined') window.tap('customer', shopName, {always_callback: true}, (err, res) => {
            // console.log('err:')
            // console.log(err)
            // console.log('res:')
            // console.log(res)
          });
          return children;
        } else {
          const redirect = Redirect.create(polaris.appBridge);
          redirect.dispatch(Redirect.Action.REMOTE, `https://${shopName}/admin/apps/upc-2?${ Date.now() }`);
          return null;
        }
      }}
    </Query>
  )
}

AuthReloader.contextTypes = {
  polaris: PropTypes.object
}

export { AuthReloader };
