import React, { Fragment } from 'react'
import { Route, match } from 'react-router-dom'
import { RenderBarcodePage, TermsPage } from '../pages'

type Props = {
  match: match;
}

export const PublicRoutes: React.SFC<Props> = ({ match }) => {
  return (
    <Fragment>
      <Route path={`${match.url}/terms-of-service`} render={() => <TermsPage />}/>
      <Route path={`${match.url}/barcode/:id`} render={({ match }) => <RenderBarcodePage id={match.params.id} />}/>
    </Fragment>
  )
}
