type parseTokenResults = {
  token: string | false | null;
  shopName: string | false | null;
}

export const parseTokenFromUrl = (queryString: string): parseTokenResults => {
  const params = new URLSearchParams(queryString);
  return {
    token: params.has('token') ? params.get('token') : false,
    shopName: params.has('shopName') ? params.get('shopName') : false
  }
}
