import React, { useEffect, useState, CSSProperties } from 'react'
import JsBarcode, { BaseOptions, Options } from 'jsbarcode'
import { saveAs } from 'file-saver'


// const document = new DOMImplementation().createDocument('http://www.w3.org/1999/xhtml', 'html', null);
// const svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg');


type Props = {
  barcode: string;
  options?: Options;
  style?: CSSProperties
}

// see https://www.npmjs.com/package/jsbarcode for options
export const BarcodeGenerator: React.FC<Props> = ({ barcode, options, style }) => {

  const [styleState, setStyle] = useState({})
  useEffect(() => {
    try {
      JsBarcode(`#barcode-${barcode}`, barcode, {format: 'EAN13', ...options});
      setStyle({...style});
    } catch (e) {
      JsBarcode(`#barcode-${barcode}`, barcode)
      console.log(e)
    }
  }, [barcode]);
  return <svg id={`barcode-${barcode}`} style={styleState}></svg>
}

type DownloadOptions = {
  barcode: string;
  options?: any;
}

export const downloadBarcodeSvg = ({ barcode, options }: DownloadOptions) => {
  const xmlSerializer = new XMLSerializer();
  const impl = document.implementation;
  const svgDocument = impl.createDocument('http://www.w3.org/1999/xhtml', 'html', null);
  var svgNode = svgDocument.createElementNS('http://www.w3.org/2000/svg', 'svg');

  JsBarcode(svgNode, barcode, {
    ...options,
      xmlDocument: svgDocument,
  });

  const svgText = xmlSerializer.serializeToString(svgNode);
  const blob = new Blob([svgText], {type: "data:image/svg+xml;charset=utf-8"});
  saveAs(blob, `${barcode}.svg`);
}
