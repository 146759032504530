import React from 'react'
import ApolloClient, { gql } from 'apollo-boost';
import { Banner as PolarisBanner, Layout } from '@shopify/polaris'
import { Query } from "react-apollo";

const ANNOUNCEMENT_QUERY = gql`
query AnnouncementQuery($first: Int, $where: AnnouncementWhereInput) {
  announcements(first: $first, where: $where) {
    id
    title
    live
    bannerStatus
    content
    action {
      content
      external
      url
      accessibilityLabel
    }
  }
}
`

export const Announcements: React.SFC = ({}) => {
  const contentClient = new ApolloClient({ uri: 'https://api-useast.graphcms.com/v1/cjvgmhjou2wjb01ghgwxcizur/master'})
  const variables = {
    "first": 1,
    "where": {
      "AND": [
    		{ "status": "PUBLISHED" },
    		{ "live": true }
  		]
    }
  };
  return(
    <Query query={ANNOUNCEMENT_QUERY} variables={variables} client={contentClient as any} >
      {({ loading, error, data: { announcements } }) => {
        if (!loading && !error && announcements.length > 0) {
          const { title, bannerStatus, action, content } = announcements[0];
          return(
            <Layout.Section>
              <PolarisBanner
                title={title}
                status={bannerStatus.toLowerCase()}
                action={action}
              >
                <p>{content}</p>
              </PolarisBanner>
            </Layout.Section>
          )
        }
        else {
          return null;
        }
      }}
    </Query>
  )
}
