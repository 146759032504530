import React, { useEffect, useState } from 'react'
import { Banner, Button, CalloutCard, Card, FooterHelp, Layout, Link, Page, TextStyle } from '@shopify/polaris'
import Tour from 'reactour'
import { ProductResourceList, StatusCard } from '../components'
import { BarcodeStore } from '../pages'
import { SearchSVG } from '../assets'

type Props = {
  token?: string | false | null;
  shopName?: string | false;
  config: TConfig;
}

type TConfig = {
  shopOrigin: string;
  apiKey: string;
}

export const Dashboard: React.FC<Props> = ({ config }) => {
  const [showTour, setShowTour] = useState(window.localStorage.getItem("showTour") === 'FALSE' ? false : true)
  useEffect(() => {
    if (window.analytics) {
        window.analytics.page('Dashboard');
    }
  })
  return (
    <Page
      title="Dashboard"
      // primaryAction={{content: 'Buy Barcodes'}}
      // secondaryActions={[
      //   {content: 'Home', disabled: true},
      //   {content: 'Documentation'}
      // ]}
      // icon="home"
    >
      <Layout>
          <BarcodeStore config={config} />
        <Layout.AnnotatedSection
          title="Your products without barcodes"
        >
          <Card>
            <ProductResourceList />
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      <Tour
        steps={steps}
        isOpen={showTour}
        onRequestClose={() => {
          window.localStorage.setItem("showTour", 'FALSE');
          setShowTour(false)
        }}
        accentColor="#43467F"
        maskClassName="helper-overlay"
        rounded={3}
        lastStepNextButton={<Button size="slim" primary>Done!</Button>} />
        <FooterHelp>
          Lost? {' '}
          <Link onClick={() => setShowTour(true)}>
            Take the tour again
          </Link>
          .
        </FooterHelp>
    </Page>
  )
}

const steps = [
  {
    selector: '#barcode-store',
    content: 'Welcome to the barcode store. You can buy barcodes here.',
  },
  {
    selector: '#pack-size-selector',
    content: 'Choose the size of the \'pack\' you want to buy. Larger packs cost less per barcode'
  },
  {
    selector: '#pack-quantity-selector',
    content: 'Choose how many packs you want to buy.'
  },
  {
    selector: '#barcode-cart-total',
    content: 'The details of your order will be reflected in real time'
  }
]
