import React, { Fragment, ReactNode } from 'react'
import {
  Card,
  Frame,
  Layout,
  Loading,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer
} from '@shopify/polaris'

type Props = {
  children?: ReactNode
}

export const LoadingPage: React.SFC<Props> = ({ children }) => (
  <SkeletonPage primaryAction secondaryActions={2}>
    <Loading />
    <Layout>
      <Layout.AnnotatedSection>
        <Card sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </Card>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection>
        <Card sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </Card>
      </Layout.AnnotatedSection>
    </Layout>
    {children}
  </SkeletonPage>
)
