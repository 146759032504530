import React, { useState, useEffect } from 'react';
import { Redirect as RouterRedirect } from 'react-router-dom'
import * as PropTypes from 'prop-types';
import {
  Banner,
  Card,
  Checkbox,
  EmptyState,
  FormLayout,
  Layout,
  Modal,
  Page,
  Select,
  TextContainer,
  TextField,
  TextStyle,
  Toast
} from '@shopify/polaris'
import createApp from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions';
import { LoadingPage } from './'
import { ErrorState, StatusCard, BARCODE_STATUS_QUERY } from '../components'
import { Mutation, Query } from "react-apollo";
import { gql } from "apollo-boost";

type Props = {
  token?: string | false | null;
  shopName?: string | false;
  config: TConfig;
}


type TConfig = {
  shopOrigin: string;
  apiKey: string;
}

const PACK_QUERY = gql`
{
  packs {
    id
    quantityOfBarcodes
    price
    currency
  }
}
`

const BUY_BARCODES = gql`
mutation BuyBarcodes($orderData: BuyBarcodesInput) {
  buyBarcodes(orderData: $orderData) {
    success
    confirmationUrl
  }
}
`;

const BarcodeStore: React.FC<Props> = ({ config }) => {

  const [selectedPack, selectPack] = useState({ id: "1", quantityOfBarcodes: 1, price: 5.00})
  const [showModal, setShowModal] = useState(false)
  const [packSize, setPackSize] = useState('0')
  const [acceptsTerms, setAcceptsTerms] = useState(false)
  const [showToast, setToast] = useState(false)
  const [showWarning, setWarning] = useState(false)
  
  const app = createApp(config)
  const redirect = Redirect.create(app);

  const orderData = {
    "selectedPack": selectedPack.id,
    "quantityOfPacks": parseInt(packSize),
    "cartTotal": parseInt(packSize) * selectedPack.price,
    "pricePerPack": selectedPack.price,
    "agreeToTerms": acceptsTerms
  }

  return (
        <Layout.AnnotatedSection
          title="Buy more barcodes"
          description={`You are buying ${packSize} packs of ${selectedPack.quantityOfBarcodes} barcodes (${ parseInt(packSize) * selectedPack.quantityOfBarcodes} total barcodes) for a total cost of $${parseInt(packSize) * selectedPack.price}`}
        >
          <Mutation mutation={BUY_BARCODES} onCompleted={(data) => {
            if (data && data.buyBarcodes && data.buyBarcodes.success) {
              console.log(data)
              redirect.dispatch(Redirect.Action.REMOTE, data.buyBarcodes.confirmationUrl);
            } else {
              setWarning(true)
              setTimeout(() => setWarning(false), 7000);
            };
            selectPack({ id: "1", quantityOfBarcodes: 1, price: 1.00})
            setPackSize('0');
          }}>
            {(buyBarcodesMutation, { data, loading }) => {
              return (
                <div id="barcode-store">
                <Card
                  primaryFooterAction={{
                    content: 'Buy barcodes',
                    loading: loading,
                    disabled: !acceptsTerms || packSize === '0',
                    onAction: () => buyBarcodesMutation({
                      variables: { orderData },
                      refetchQueries: [{ query: BARCODE_STATUS_QUERY }]
                    })
                  }}
                >
                  <StatusCard />
                  <Card.Section>
                    {showToast && (
                      <Toast content={data.buyBarcodes.message} onDismiss={() => setToast(false)}/>
                    )}
                    <FormLayout>
                      <FormLayout.Group>
                        <Query query={PACK_QUERY}>
                          {({ loading, error, data: { packs } }) => {
                            const options = !loading
                              ? packs.map((pack: any) => ({ label: `${pack.quantityOfBarcodes} barcodes @ $${(pack.price / pack.quantityOfBarcodes).toFixed(2)}/barcode ($${pack.price})`, value: pack.id, ...pack }))
                              : [{ label: "Loading...", value: "loading"}]
                            return (
                              <div id="pack-size-selector">
                                <Select label="Pack size" options={options} value={selectedPack.id} disabled={loading} onChange={(packId) => {
                                  selectPack(options.find((pack:any) => pack.id === packId))
                                }} />
                              </div>
                            )
                          }}
                        </Query>
                        <div id="pack-quantity-selector">
                          <TextField
                            label="Number of packs"
                            type="number"
                            max={20}
                            min={0}
                            value={packSize}
                            onChange={(value) => setPackSize(value)}
                            helpText={packSize === '0' ? "'Number of packs' must be greater than 0" : null}
                          />
                        </div>
                      </FormLayout.Group>
                      {data && data.buyBarcodes && !data.buyBarcodes.success && showWarning && (
                        <FormLayout.Group>
                          <TextContainer>
                            <Banner status="critical">
                              <p>
                                {data.buyBarcodes.message}
                              </p>
                            </Banner>
                          </TextContainer>
                        </FormLayout.Group>
                      )}
                      <FormLayout.Group>
                        <Checkbox
                          checked={acceptsTerms}
                          label={<div>By checking this box, I agree to the <span onClick={() => setShowModal(!showModal)} style={{textDecoration: "underline"}}>terms of service.</span></div>}
                          onChange={(value: boolean) => setAcceptsTerms(value)}
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <div id="barcode-cart-total">
                          You are buying {packSize} packs of {selectedPack.quantityOfBarcodes} barcodes ({ parseInt(packSize) * selectedPack.quantityOfBarcodes} total barcodes) for a total cost of ${parseInt(packSize) * selectedPack.price}
                        </div>
                      </FormLayout.Group>
                    </FormLayout>
                    <Modal
                      large
                      open={showModal}
                      onClose={() => {
                        setAcceptsTerms(true)
                        setShowModal(!showModal)
                      }}
                      title="Terms and conditions"
                      primaryAction={{
                        content: 'Accept terms and conditions',
                        onAction: () => {
                          setAcceptsTerms(true)
                          setShowModal(false)
                        }
                      }}
                      src="/p/terms-of-service"
                    />
                  </Card.Section>
                </Card>
                </div>
              )
            }}
          </Mutation>
        </Layout.AnnotatedSection>
  )
}

BarcodeStore.contextTypes = {
  polaris: PropTypes.object
}

export { BarcodeStore }
