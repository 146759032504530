import React from 'react'
import { Card, Checkbox, DataTable, Layout, List, Page, TextContainer } from '@shopify/polaris'

export const BulkProducts: React.FC = () => {
  console.log(window.location.href)
  return (
    <Page title="Bulk Operation">
      <Layout>
        <Layout.Section>
          <Card title="Please review the bulk operation" primaryFooterAction={{content: 'Perform bulk action'}} secondaryFooterActions={[{ content: 'Go back'}]} sectioned>
            <TextContainer>
              <List>
                <List.Item>You are about to use <span>70</span> of your <span>100</span> unused UPC Codes on this Bulk Operation.</List.Item>
                <List.Item>This operation will apply 70 UPC Codes to 70 variants across 12 products</List.Item>
                <List.Item>8 variants already have the Shopify "Barcode" field populated. These varants will not be overwritten.</List.Item>
                <List.Item>Are you ready to proceed?</List.Item>
              </List>
              <Checkbox
                label="I understand that this operation in permenant, and will use 70 of my UPC Codes"
                checked={true}
                onChange={() => console.log('check toggled')}
              />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card
            title="The bulk operation will be performed on the following products"
            primaryFooterAction={{content: 'Perform bulk action'}}
            secondaryFooterActions={[{ content: 'Go back'}]}
          >
            <Card.Section fullWidth>
              <DataTable
                headings={[
                  'Product',
                  'Total Variants',
                  'Variants to ignore',
                  'Variants to update'
                ]}
                columnContentTypes={[
                  'text',
                  'numeric',
                  'numeric',
                  'numeric',
                ]}
                rows={rows}
                totals={['','', 5, 7]}
                footerContent={<span style={{cursor: 'pointer'}}>Showing {rows.length} of 254 results. <span style={{textDecoration: 'underline'}}>Load more.</span></span>}
                // upgrade needed
                // showTotalsInFooter={true}
              />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}


const rows = [
  ['Black NASA Hat', 3, 0, 3],
  ['Black NASA Shirt', 3, 0, 3],
  ['Red NASA Shirt', 3, 3, 0],
  ['Red NASA Hat', 3, 2, 1]
]
