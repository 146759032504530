import React from 'react'
import { BarcodeGenerator } from '../components'

type Props = {
  id: string;
}

export const RenderBarcodePage: React.SFC<Props> = ({ id }) => {
  return (
    <div style={{padding: '10px', width: "100%", margin: "auto", textAlign: "center", background: "#ffffff"}}>
      <BarcodeGenerator barcode={id} />
    </div>
  )
}
