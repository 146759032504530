import React, { useEffect, useState } from 'react';
import { Redirect as RouterRedirect } from 'react-router-dom'
import * as PropTypes from 'prop-types';
import { EmptyState, Page } from '@shopify/polaris'
import createApp from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions';
import { LoadingPage } from './'
import { ErrorState } from '../components'
import { WelcomeSVG } from '../assets'
import { Query } from "react-apollo";
import { gql } from "apollo-boost";

type Props = {
  token?: string | false | null;
  shopName?: string | false;
  config: TConfig;
}

type TConfig = {
  shopOrigin: string;
  apiKey: string;
}

const SHOP_DETAILS_QUERY = gql`
{
  shopDetails {
    recurringApplicationCharges {
      status
      confirmationUrl
    }
  }
}`

const { REACT_APP_SHOPIFY_KEY, REACT_APP_SERVER_URL } = process.env;

const WelcomePage: React.FC<Props> = ({ token, config }) => {
  const [emptyStateTab, setEmptyStateTab] = useState(0)
  useEffect(() => {
    if (window.analytics) {
        window.analytics.page('Welcome Page');
    }
  }, [token])

  const app = createApp(config)
  const redirect = Redirect.create(app);
  return (
    <Page title="Welcome">
      <Query query={SHOP_DETAILS_QUERY}>
        {({loading, error, data }) => {
          if (error) return <ErrorState error={{component: "WelcomePage", ...error }} />
          if (loading) {
            return <LoadingPage />
          } else if (
            data
            && data.shopDetails
          ) {
            return <RouterRedirect to="/a/dashboard" />
          } else {
            return (
              <EmptyState
                heading="Sell Your Custom Products on Amazon, Houzz, and More"
                action={{
                  loading,
                  content: 'Set up billing',
                  onAction: () => {
                    if (!loading) {
                      if (window.analytics) {
                        window.analytics.track('Begin Create Recurring Charge');
                      }
                      const redirectUrl: string = data.shopDetails.recurringApplicationCharges.status === 'pending'
                        ? data.shopDetails.recurringApplicationCharges.confirmationUrl
                        : `${REACT_APP_SERVER_URL}/charge/new?token=${token}`;
                      redirect.dispatch(Redirect.Action.REMOTE, redirectUrl);
                    }
                  }
                }}
                secondaryAction={{content: 'Learn more', external: true, url: 'https://ventures-adventures.com?utm_source=upc-codes-react'}}
                footerContent={
                  <p>In order to purchase barcodes, you need to first authorize a $0 recurring charge (like starting a bar tab). When you purchase barcodes through the app we will charge a one-time expense to your Shopify account.</p>
                }
                image={WelcomeSVG}
              >
                <p>UPC Code Manager is <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>free to use forever</span>. Pay only when you purchase barcodes through the app.</p>
              </EmptyState>
            )
          }

        }}
      </Query>
    </Page>
  )
}

WelcomePage.contextTypes = {
  polaris: PropTypes.object
}

export { WelcomePage }
