import React, { Fragment, useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Avatar, Badge, Banner, ButtonGroup, Button, Card, DisplayText, Layout, Modal, Page, ProgressBar, ResourceList, ResourceItem, Stack, Tabs, TextContainer, TextStyle, Thumbnail, Toast } from '@shopify/polaris'
import { Query, Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'
import Interweave from 'interweave';
import { LoadingPage } from './'
import { BarcodeGenerator, ErrorState, downloadBarcodeSvg } from '../components'
import { CelebrationSVG } from '../assets'


const PRODUCT_DETAIL_QUERY = gql`
query ProductDetails($productId: String){
  products(ids: $productId) {
    nodes {
      id
      title
      bodyHtml
      images {
        src
      }
      variantStats {
        active
        needed
        total
        progress
      }
      variants {
        id
        title
        barcode
      }
    }
  }
}
`

const ADD_BARCODES_MUTATION = gql`
mutation AddBarcodesToVariants($variantsToGetBarcodes: [VariantToLinkInput!]!) {
  addBarcodesToVariants(variants: $variantsToGetBarcodes) {
    id
    barcode
  }
}
`

type TVariantStats = {
  total: number;
  needed: number;
}

type TVariant = {
  id: string;
  title: string;
  barcode: string;
}

type Props = {
  productId: string
}

export const ProductDetail: React.SFC<Props> = ({ productId }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedVariants, setSelectedVariants] = useState([])
  const [showToast, setToast] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [pollInterval, setPollInterval] = useState(0);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [modalBarcode, setModalBarcode] = useState('')

  const tabs = [
    {
      id: 'needs-barcode',
      content: 'Need codes',
      accessibilityLabel: 'Variants needing barcode',
      panelID: 'variants-need-content',
    },
    {
      id: 'have-barcode',
      content: 'Have codes',
      accessibilityLabel: 'Variants with barcode',
      panelID: 'variants-have-content',
    },
  ]

  useEffect(() => {
    if (window.analytics) {
        window.analytics.page('Product Detail Page');
    }
  }, [productId])


  return (
    <Query query={PRODUCT_DETAIL_QUERY} variables={{ productId }}>
      {({ loading, error, data, refetch }) => {
        if (error) return <ErrorState error={{component: "ProductDetail", ...error }} />
        if (loading) {
          return <LoadingPage />
        } else {
          const product = data && data.products && data.products.nodes && data.products.nodes[0] ? data.products.nodes[0] : { title: "Undefined" };
          return (
            <Route render={({ history }) => (
              <Page title="Product Details">
                <Page
                  title={`${product.title} (${product.variantStats.total} variants)`}
                  breadcrumbs={[{content: 'Home', onAction: () => history.push('/a/dashboard')}]}
                  // forceRender={true}
                >
                  <Layout>
                    {showToast && (
                      <Toast content={`Successfully linked barcodes to variants`} onDismiss={() => setToast(false)}/>
                    )}
                    <Mutation
                      mutation={ADD_BARCODES_MUTATION}
                      update={(cache: any, { data: { addBarcodesToVariants }}) => {
                        if (!addBarcodesToVariants) return;
                        setToast(true);
                        setSelectedVariants([]);
                        refetch();
                        if (window.analytics) {
                          window.analytics.track("Linked Codes to Variants", { numberOfVariants: addBarcodesToVariants.length })
                        }
                      }}
                      onError={(mutationError) => {
                        setErrorMessage(mutationError.message);
                        if (window.analytics) {
                          window.analytics.track("Error Linking Codes to Variants", mutationError);
                        }
                        setSelectedVariants([])
                        setPollInterval(100)
                      }}
                    >
                      {(addBarcodesToVariantsMutation, { loading: mutationLoading, error: mutationError }) => {
                        // if (mutationError) {
                        //   setErrorMessage(mutationError.message);
                        //   if (window.analytics) {
                        //     window.analytics.track("Error Linking Codes to Variants", mutationError);
                        //   }
                        //   setSelectedVariants([])
                        //   setPollInterval(500)
                        // }
                        const renderCardAction = ({ total, needed }: TVariantStats) => {
                          if (total === 1) {
                            return {
                              loading: mutationLoading,
                              content:  `Add barcode to ${needed} variant${needed > 1 ? 's' : ''}`,
                              disabled: needed === 0,
                              onAction: () => {
                                const variantsToGetBarcodes = product.variants.map((variant: any) => ({id: variant.id}));
                                return addBarcodesToVariantsMutation({variables: { variantsToGetBarcodes }})
                              }
                            }
                          }
                        }

                        const renderCardSecondaryActions = ({ total, needed }: TVariantStats) => {
                          if (total === 1 && needed === 0) {
                            return [{
                              loading: mutationLoading,
                              content: "View barcode image",
                              disabled: needed !== 0,
                              onAction: () => {
                                setModalIsOpen(true)
                                setModalBarcode(product.variants[0].barcode)
                              }
                            }]
                          }
                        }

                        return (
                          <Fragment>
                            <Layout.AnnotatedSection title="Product Details">
                              <Card
                                title={product.title}
                                primaryFooterAction={renderCardAction(product.variantStats)}
                                secondaryFooterActions={renderCardSecondaryActions(product.variantStats)}
                              >
                                {product.images.length >= 1 && (
                                  <Card.Section>
                                    <Stack>
                                      {product.images.map((image: any, i: number) => (
                                        <Thumbnail
                                          key={i}
                                          source={image.src}
                                          size="large"
                                          alt={`${product.title} product image ${i}`}
                                        />
                                      ))}
                                    </Stack>
                                  </Card.Section>
                                )}
                                <Card.Section title={`${product.variantStats.active} / ${product.variantStats.total} variants with barcodes`}>
                                  <ProgressBar progress={product.variantStats.progress * 100} size="medium" />
                                </Card.Section>
                                {product.variantStats.total === 1 && (
                                  <Card.Section>
                                    <Stack>
                                      <Stack.Item fill>
                                        <TextStyle variation="strong">Variant: "{product.variants[0].title}"</TextStyle>
                                      </Stack.Item>
                                      <Stack.Item>
                                        {renderBarcodeStatus(product.variants[0].barcode)}
                                      </Stack.Item>
                                    </Stack>
                                  </Card.Section>
                                )}
                                <Card.Section title="Description">
                                  <Interweave content={product.bodyHtml} />
                                </Card.Section>
                                {errorMessage && product.variantStats.total === 1 && (
                                  <Card.Section>
                                    <Banner status="critical" onDismiss={() => {setErrorMessage('')}}>
                                      {errorMessage}
                                    </Banner>
                                  </Card.Section>
                                )}
                                <Modal
                                  open={isModalOpen}
                                  onClose={() => {
                                    setModalIsOpen(false)
                                    setModalBarcode('');
                                  }}
                                  title={`Barcode: ${modalBarcode}`}
                                  primaryAction={{
                                    content: 'Download SVG',
                                    onAction: () => downloadBarcodeSvg({ barcode: modalBarcode }),
                                  }}
                                  src={`/p/barcode/${modalBarcode}`}
                                  limitHeight={false}
                                />
                              </Card>
                            </Layout.AnnotatedSection>{product.variantStats.total > 1 && (
                              <Layout.AnnotatedSection
                                title="Product variants"
                                description={`You have `}
                              >
                                <Card>
                                  {errorMessage && (
                                    <Card.Section>
                                      <TextContainer>
                                        <Banner
                                          status="critical"
                                          onDismiss={() => setErrorMessage('')}
                                          action={{content: 'Refetch', loading, onAction: () => {
                                            console.log('refetching...')
                                            refetch();
                                            setTimeout(() => {
                                              setErrorMessage('')
                                            }, 1000)
                                          }}}
                                        >
                                          {errorMessage}
                                        </Banner>
                                      </TextContainer>
                                    </Card.Section>
                                  )}
                                  {selectedVariants.length > 40 && (
                                    <Card.Section>
                                      <TextContainer>
                                        <Banner status="warning">
                                          Too many variants selected to process at once. Please select less variants to proceed.
                                        </Banner>
                                      </TextContainer>
                                    </Card.Section>
                                  )}
                                  <Tabs
                                    tabs={tabs}
                                    selected={selectedTab}
                                    onSelect={(selectedTabIndex) => setSelectedTab(selectedTabIndex)}
                                  >
                                    {tabs[selectedTab].id === 'needs-barcode'
                                      ? product.variants.filter((variant: any) => !variant.barcode).length > 0
                                        ? (
                                          <ResourceList
                                            loading={mutationLoading || errorMessage ? true : false}
                                            items={product.variants.filter((variant: any) => !variant.barcode)}
                                            resourceName={{singular: 'product', plural: 'products'}}
                                            selectedItems={selectedVariants}
                                            onSelectionChange={(selectedVariants: any) => {setSelectedVariants(selectedVariants)}}
                                            promotedBulkActions={[{
                                              content: `Add barcodes to ${selectedVariants.length} variant${selectedVariants.length > 1 ? 's' : ''}`,
                                              onAction: () => {
                                                const variantsToGetBarcodes = selectedVariants.map(variantId => ({id: variantId}));
                                                return addBarcodesToVariantsMutation({variables: { variantsToGetBarcodes }})
                                              },
                                              disabled: selectedVariants.length > 40
                                            }]}
                                            renderItem={(variant: TVariant) => (
                                              <ResourceItem
                                                id={variant.id}
                                                accessibilityLabel={`Barcode information for ${variant.title}`}
                                                onClick={() => null}
                                              >
                                                <Stack>
                                                  <Stack.Item fill>
                                                    <TextStyle variation="strong">{variant.title}</TextStyle>
                                                  </Stack.Item>
                                                  <Stack.Item>
                                                    {renderBarcodeStatus(variant.barcode)}
                                                  </Stack.Item>
                                                </Stack>
                                              </ResourceItem>
                                            )}
                                          />
                                        ) : (
                                          <div style={{ padding: '20px', textAlign: 'center'}}>
                                            <DisplayText size="medium">All of your variants have codes!</DisplayText>
                                            <img src={CelebrationSVG} style={{maxWidth: '100%', padding: '20px'}}/>
                                          </div>
                                        )
                                      : (
                                        <ResourceList
                                          loading={mutationLoading}
                                          items={product.variants.filter((variant: any) => variant.barcode)}
                                          resourceName={{singular: 'product', plural: 'products'}}
                                          selectedItems={selectedVariants}
                                          onSelectionChange={(selectedVariants: any) => {
                                            setSelectedVariants(selectedVariants)
                                          }}
                                          renderItem={(variant: TVariant) => (
                                            <ResourceItem
                                              id={variant.id}
                                              accessibilityLabel={`Barcode information for ${variant.title}`}
                                              onClick={() => {
                                                setModalBarcode(variant.barcode);
                                                setModalIsOpen(true)
                                              }}
                                              media={<BarcodeGenerator barcode={variant.barcode} style={{width: "4rem", height: "auto"}} />}
                                            >
                                              <Stack>
                                                <Stack.Item fill>
                                                  <TextStyle variation="strong">{variant.title}</TextStyle>
                                                </Stack.Item>
                                                <Stack.Item>
                                                  {renderBarcodeStatus(variant.barcode)}
                                                </Stack.Item>
                                              </Stack>
                                            </ResourceItem>
                                          )}
                                        />
                                      )
                                    }
                                  </Tabs>

                                </Card>
                              </Layout.AnnotatedSection>
                            )}
                          </Fragment>
                        )
                      }}
                    </Mutation>
                  </Layout>
                </Page>
              </Page>
            )}/>
          )
        }
      }}
    </Query>
  )
}


const renderBarcodeStatus = (barcode: string | undefined) => {
  return barcode
      ? <TextStyle variation="subdued">Barcode: {barcode}</TextStyle>
      : <Badge status="attention">Barcode needed</Badge>;
}
