import React from 'react'
import { EmptyState } from '@shopify/polaris'
import BugsSVG from '../assets/bugs.svg'

type Props = {
  error?: any;
}

export const ErrorState: React.SFC<Props> = ({ error }) => {
  console.error(error);
  return (
    <EmptyState
      heading="Oops! Something went wrong"
      action={{
        content: "Contact support",
        url: "mailto:help@ventures-adventures.com"
      }}
      image={BugsSVG}
    >
      <p>This error has been recorded and we are looking into it. If you need immediate help please send us an email.</p>
    </EmptyState>
  )
}
