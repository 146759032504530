import React from 'react'


type Props = {
  searchParams: string;
}

export const ServerAuth: React.SFC<Props> = ({ searchParams }) => {
  console.log(searchParams)
  const { NODE_ENV, REACT_APP_SERVER_URL, REACT_APP_PROD_SERVER_URL } = process.env;
  const serverUrl = NODE_ENV === 'development'
    ? REACT_APP_SERVER_URL
    : REACT_APP_PROD_SERVER_URL;
  const urlParams = new URLSearchParams(searchParams);
  if (window.analytics && urlParams.has('shop')) {
    window.analytics.identify(urlParams.get('shop'), { displayName: urlParams.get('shop')})
    window.analytics.page('Begin Auth')
  }
  setTimeout(() => window.location.href = `${serverUrl}/auth${searchParams}`, 500)
  return null;
}
