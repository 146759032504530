import React, { useState, useEffect } from 'react'
import { Badge, Card, ResourceList, ResourceItem, Stack, TextStyle, Thumbnail } from '@shopify/polaris'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import { LoadingPage } from '../pages'
import { ErrorState } from '../components'

const PRODUCT_VARIANTS_QUERY = gql`
query ProductVariants($productId: String) {
  products(id: $productId) {
    nodes {
      variants {
        id
        title
        barcode
      }
    }
  }
}
`

type TProductDetailProps = {
  productId: string
}

export type TVariant = {
  id: string;
  title: string;
  barcode?: string;
}

export const ProductDetailCard: React.SFC<TProductDetailProps> = ({ productId }) => {
  const [selectedItems, setSelectedItems] = useState([])

  return (
    <Card>
      <Query query={PRODUCT_VARIANTS_QUERY} variables={{ productId }}>
        {({ loading, error, data: { products } }) => {
          if (error) return <ErrorState error={{component: "ProductDetail", ...error }} />
          if (loading) {
            return null;
          } else {
            return (
              <ResourceList
                loading={loading}
                items={products && products.nodes[0].variants}
                resourceName={{singular: 'product', plural: 'products'}}
                selectedItems={selectedItems}
                onSelectionChange={(selectedItems: any) => setSelectedItems(selectedItems)}
                promotedBulkActions={[{ content: "Add barcodes", onAction: () => null}]}
                renderItem={(variant: TVariant) => (
                  <ResourceItem
                    id={variant.id}
                    accessibilityLabel={`Barcode information for ${variant.title}`}
                    onClick={() => null}
                  >
                    <Stack>
                      <Stack.Item fill>
                        <TextStyle variation="strong">{variant.title}</TextStyle>
                      </Stack.Item>
                      <Stack.Item>
                        {renderBarcodeStatus(variant.barcode)}
                      </Stack.Item>
                    </Stack>
                  </ResourceItem>
                )}
             />
            )
          }
        }}
      </Query>
    </Card>
  )
}

const renderBarcodeStatus = (barcode: string | undefined) => {
  return barcode
      ? <TextStyle variation="subdued">Barcode: {barcode}</TextStyle>
      : <Badge status="attention">Barcode needed</Badge>;
}
