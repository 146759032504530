import React from 'react';
import { BrowserRouter as Router, Route, } from "react-router-dom";
import { ServerAuth, AuthRoutes, PublicRoutes } from './routes'
import { parseTokenFromUrl } from './utilities'
declare global {
    interface Window { analytics: any; }
}

const App: React.SFC = () => {
  console.log(window.location.search)
  const { token, shopName } = parseTokenFromUrl(window.location.search);
  sessionStorage.setItem('shopName', shopName ? shopName : 'FALSE');
  sessionStorage.setItem('token', token ? token : 'FALSE');
  return (
    <Router>
      <Route path="/auth" exact render={() => <ServerAuth searchParams={window.location.search} />} />
      <Route path="/a" render={({match}) => <AuthRoutes token={token} shopName={shopName} match={match} />} />
      <Route path="/p" render={({match}) => <PublicRoutes match={match} />} />
    </Router>
  )
}

export default App;
