import React, { useEffect } from 'react'
import { Route, match } from 'react-router-dom'
import { ApolloProvider } from "react-apollo";
// import ApolloClient from 'apollo-boost';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { RetryLink } from "apollo-link-retry";
import { InMemoryCache } from 'apollo-cache-inmemory'
import { AppProvider, Frame } from '@shopify/polaris'
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react'
import { BarcodeStore, BulkProducts, Dashboard, ProductDetail, WelcomePage } from '../pages'
import { AuthReloader } from '../components'
import translations from '@shopify/polaris/locales/en.json';

type Props = {
  token: string | false | null;
  shopName: string | false | null;
  match: match;
}

const { REACT_APP_SERVER_URL, REACT_APP_SHOPIFY_KEY } = process.env;


export const AuthRoutes: React.SFC<Props> = ({ token, shopName, match }) => {
  const client = new ApolloClient({
    link: ApolloLink.from([
      new RetryLink(),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      new HttpLink({
        uri: `${REACT_APP_SERVER_URL}/graphql`,
        headers: { Authorization: token }
      })
    ]),
    cache: new InMemoryCache(),
  });
  // const client = new ApolloClient({
  //   uri: `${REACT_APP_SERVER_URL}/graphql`,
  //   headers: { Authorization: token },
  //   // cache: new InMemoryCache()
  // });

  const sessionShopName: string | null = typeof shopName === 'string'
    ? shopName
    : typeof sessionStorage.getItem('shopName') === 'string'
      ? sessionStorage.getItem('shopName')
      : 'FALSE';
  useEffect(() => {
    if (window.analytics) {
      window.analytics.identify(sessionShopName, { displayName: sessionShopName })
    }
  }, [shopName])

  const config = {apiKey: REACT_APP_SHOPIFY_KEY ||'FALSE', shopOrigin: typeof sessionShopName === 'string' ? sessionShopName : 'FALSE'};

  return (
    <AppProvider i18n={translations}>
      <AppBridgeProvider config={config}>
        <Frame>
          <ApolloProvider client={client as any}>
            <AuthReloader token={token} shopName={shopName}>
              <Route path={`${match.url}/welcome`} render={() => <WelcomePage token={token} config={config} />}/>
              <Route path={`${match.url}/bulk/products`} render={() => <BulkProducts />}/>
              <Route path={`${match.url}/dashboard`} render={() => <Dashboard config={config} />} />
              <Route path={`${match.url}/store`} render={() => <BarcodeStore config={config} />} />
              <Route path={`${match.url}/products/:id`} render={({ match }) => <ProductDetail productId={match.params.id} />} />
            </AuthReloader>
          </ApolloProvider>
        </Frame>
      </AppBridgeProvider>
    </AppProvider>
  )
}
