import React from 'react';

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px 16px',
  borderTop: '1px solid #dfe4e8'
}

export const ResourceListFooter: React.SFC = ({ children }) => (
  <div style={style}>{children}</div>
)
