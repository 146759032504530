import React, { ReactElement, useState } from 'react'
import { Route } from 'react-router-dom'
import { Avatar, Badge, Pagination, ResourceList, ResourceItem, Stack, Tabs, TextStyle } from '@shopify/polaris'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import { ErrorState, ResourceListFooter } from './'
import { IProduct } from 'shopify-api-node'

const PRODUCT_QUERY = gql`
query ProductListQuery($page: Int, $params: PageParamsInput) {
  products(page: $page, params: $params) {
    nextPageParameters {
      limit
      page_info
      fields
    }
    previousPageParameters {
      limit
      page_info
      fields
    }
    nodes {
      id
      title
      variants {
        id
        title
        barcode
      }
      images {
        position
        src
      }
      variantStats {
        total
        active
        needed
      }
    }
  }
}
`


type Props = {

}

type IPageParams = {
  limit: string;
  page_info: string;
} | undefined;

export const ProductResourceList: React.SFC<Props> = ({}) => {
  const [page, setPage] = useState(1)
  const [params, setParams] = useState<IPageParams>(undefined)
  const resourceName = {
    singular: 'product',
    plural: 'products',
  };

  return (
    <Query query={PRODUCT_QUERY} variables={{ page, params }}>
    {({loading, error, data: { products }, refetch}) => {
      if (error) return <ErrorState />
      console.log(products)

      let nextPageParameters = products && products.nextPageParameters;
      if (nextPageParameters && nextPageParameters.__typename) {
        delete nextPageParameters.__typename;
      }
      let previousPageParameters = products && products.previousPageParameters;
      if (previousPageParameters && previousPageParameters.__typename) {
        delete previousPageParameters.__typename
      }

      return (
        <Tabs tabs={[{id: "all-products", content: "All Products"}]} selected={0}>
          <ResourceList
            resourceName={resourceName}
            items={products && products.nodes ? products.nodes : []}
            renderItem={(item: EnrichedProduct) => <RenderItem product={item} />}
            loading={loading}
          />
          <ResourceListFooter>
            <Pagination
              hasPrevious={previousPageParameters}
              onPrevious={() => {
                console.log(previousPageParameters);
                console.log(previousPageParameters);
                setParams(previousPageParameters)
              }}
              hasNext={nextPageParameters}
              onNext={() => {
                console.log(nextPageParameters);
                console.log(nextPageParameters);
                setParams(nextPageParameters)
              }}
            />
          </ResourceListFooter>
        </Tabs>
      )
    }}
    </Query>
  )
}

interface EnrichedProduct extends IProduct {
  variantStats?: VariantStats;
}

type VariantStats = {
  total?: number;
  active?: number;
  needed?: number;
  progress?: number;
}

type RenderItemProps = {
  product: EnrichedProduct;
}

const RenderItem: React.SFC<RenderItemProps> = ({
  product: {
    id,
    title = 'Unnamed product',
    images,
    variantStats: { total, needed, active } = {}
  }
}) => {
  return (
    <Route render={({ history }) => (
      <ResourceItem
          id={id ? String(id) : title}
          accessibilityLabel={`View details for ${title}`}
          onClick={() => history.push(`/a/products/${id}`)}
          media={<Avatar size="large" source={images && images[0] ? images[0].src : undefined}/>}
        >
        <h3>
          <TextStyle variation="strong">{title}</TextStyle>
        </h3>
        <div>{`${total} product variant${total !== 1 ? 's' : ''}`}</div>
        <VariantBadges needed={needed} active={active} />
      </ResourceItem>
    )}/>

  )
}

const VariantBadges: React.SFC<VariantStats> = ({ total, active, needed}) => {
  return (
    <Stack>
      <Badge size="small" status={needed === 0 ? "success" : "warning"}>{`${String(needed)} barcode${needed === 1 ? '' : 's'} needed`}</Badge>
      <Badge size="small">{`${String(active)} variant${active === 1 ? '' : 's'} with barcodes`}</Badge>
    </Stack>
  )
}
